import React, { useEffect } from "react"
import { Link } from "gatsby"
import "./index.scss"

const Breadcrumbs = props => {
  const breadcrumbs = props.items.map(crumb => {
    return (
      <>
        <Link to={crumb.url} className="link" activeClassName="active">
          {crumb.handle}
        </Link>
        &nbsp;/&nbsp;
      </>
    )
  })
  console.log(breadcrumbs);
  return (
    <div className="breadcrumbs">
      <div className="breadcrumbs-breadcrumbs">
        <Link to="/" className="link" activeClassName="active">
          Home
        </Link>
        &nbsp;/&nbsp;
        {breadcrumbs}
      </div>
    </div>
  )
}

export default Breadcrumbs

import React from "react"
import "./index.scss"

const SimpleText = ({ ...props }) => (
  <section className="simple-text">
    <div className="wrapper">
      {props.heading && (<h2>{props.heading}</h2>)}
      <p>{props.copy}</p>
      {props.children && (props.children)}
    </div>
  </section>
)

export default SimpleText

import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import Layout from "src/components/layout"
import SubpageBanner from "src/components/SubpageBanner"
import SEO from "src/components/seo"
import SimpleText from "src/components/simple-text"
import Breadcrumbs from "src/components/Breadcrumbs"
import Sidebar from "src/components/Sidebar"

const breadcrumbs = [
  {url: "womens-health-impact", handle: "Women's Health Impact"},
  {
    url: "womens-health-impact/real-world-data",
    handle: "Real World Data",
  },
]

const RealWorldData = () => (
  <StaticQuery
    query={graphql`
      query {
        one: file(relativePath: { eq: "excellerator-3.jpg" }) {
          childImageSharp {
            fixed(quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        background: file(relativePath: { eq: "discarded-cells-banner.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      const one = data.one.childImageSharp.fixed
      const background = data.background.childImageSharp.fluid

      return (
        <Layout>
          <SEO title="Real World Data - ExCellerator" />
          <SubpageBanner image={background} heading="Real World Data" />
          <div className="wrapper wrapper--narrow p-bottom p-sides">
            <Breadcrumbs items={breadcrumbs} />
            <div className="split-contain wrapper">
              <div className="split-contain__sidebar">
                <Sidebar image={one} />
              </div>
              <div className="split-contain__contain">
                <SimpleText
                  copy="Immediately following the introduction of the ThinPrep Pap Test system, clients began to experience and complain about results of specimen inadequacy from samples they contended were quality collections. We experienced clients actually switching back to the conventional method out of frustration. We knew that the new system was better overall, so we set out to find out what was causing the issue."
                >
                  <h3>RWE Data</h3>
                  <ul>
                    <li>The device was utilized in clinical practice (off-label) to improve specimen transfer and tests results for the benefit of over 1 million women.</li>
                    <li>Data Sets from over 300,000 Pap Test Events for different spatulas and collection methodologies were compared.</li>
                    <li>All Data are also compared against benchmark CAP – National Percentage Data.</li>
                  </ul>
                  <h3>Sample Collection:</h3>
                  <ul>
                    <li>The exCellerator spatula was used as both a primary and a complimentary (secondary) device to collect cervical samples for ThinPrep testing in the stead of the standard and approved spatula.</li>
                  </ul>
                  <h3>Results/Conclusions:</h3>
                  <ul>
                    <li>UNSAT Rate was 0.5% versus 1.3% (CAP National Data)</li>
                    <li>LSIL or Greater Diagnosis rate was 6.1% with exCellerator versus 4.1% without, in the same patient population.</li>
                    <li>Diagnosis of HSIL or ASC-H rate was 1.3% with exCellerator versus 0.8% without, in the same patient population.</li>
                  </ul>
                </SimpleText>
              </div>
            </div>
          </div>
        </Layout>
      )
    }}
  />
)

export default RealWorldData

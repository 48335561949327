import React from "react"
import BackgroundImage from "gatsby-background-image"
import "./index.scss"

const SubpageBanner = (props) => (
  <BackgroundImage
    Tag="section"
    className="subpage-banner"
    fluid={props.image}
    fadeIn={false}
    loading={"eager"}
    style={{
      // Defaults are overwrite-able by setting one or each of the following:
      backgroundColor: '#9A66A5',
      backgroundPosition: 'right',
      backgroundRepeat: 'no-repeat',
    }}
  >
    <div className="subpage-banner__info wrapper wrapper--narrow p-sides">
      <h1>
        {props.heading}
      </h1>
    </div>
  </BackgroundImage>
)

export default SubpageBanner

import React from "react"
import Img from "gatsby-image"
import "./index.scss"

const Sidebar = (props) => (
  <section className="sidebar">
    {props.image && 
      <div className="sidebar__image">
        <Img fixed={props.image} className="sidebar__image-img" />
      </div>
    }
    {props.copy &&
      <h3 className="sidebar__copy">
        {props.copy}
      </h3>
    }
  </section>
)

export default Sidebar
